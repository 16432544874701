@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$nav-pills-link-active-bg: $dark;
$nav-pills-link-active-color: $light;

$link-color: $dark;
$link-hover-color: shift-color($link-color, $link-shade-percentage);

$nav-link-color: $link-color;
$nav-link-hover-color: $link-hover-color;

@import "bootstrap/scss/bootstrap";

@media only screen and (min-width: 576px) {
    .sidebar {
        height: 100vh;
        position: sticky;
        top: 0;
        left: 0;
    }
}
